import React from 'react';
import PropTypes from 'prop-types';
import styles from './HelpBlock.module.scss';
import Button from '../../components/Button';
import isEmpty from '../../utils/isEmpty';
import classNames from 'classnames';
import useMediaQuery from '../../utils/useMediaQuery';
import i18n from '../../i18n';
import { breakpoints } from '../../constants';

const HelpBlock = ({ title, preamble, items }) => {
    return (
        <div className={styles['HelpBlock']}>
            <div className={styles['HelpBlock__Container']}>
                {title && (
                    <h2 className={styles['HelpBlock__Title']}>{title}</h2>
                )}
                {preamble && (
                    <p className={styles['HelpBlock__Text']}>{preamble}</p>
                )}

                {!isEmpty(items) && (
                    <div className={styles['HelpBlock__Blocks']}>
                        {items.map((item) => {
                            return <Block {...item} key={item.icon} />;
                        })}
                    </div>
                )}
            </div>
        </div>
    );
};

const Block = ({ title, subtext, button, buttonText, icon, href }) => {
    const isMobile = useMediaQuery({
        query: `(max-width: ${breakpoints.m}px)`,
    });

    const Tag = isMobile ? 'a' : 'div';

    let props = {};

    if (isMobile) {
        props.href = href;
    } else {
        button = {
            link: href,
            text: buttonText || i18n.t('button.readMore'),
            type: 'Secondary',
        };
    }

    const iconClasses = classNames(styles['HelpBlock__Icon'], {
        [styles[`HelpBlock__Icon--${icon}`]]: icon,
    });

    const blockClasses = classNames(styles['HelpBlock__Block'], {
        [styles['HelpBlock__Block--Link']]: isMobile,
        [styles[`HelpBlock__Block--${icon}`]]: icon,
    });

    return (
        <Tag {...props} className={blockClasses}>
            <div className={styles['HelpBlock__BlockInner']}>
                {icon && <div className={iconClasses} />}
                {title && (
                    <h3 className={styles['HelpBlock__BlockTitle']}>{title}</h3>
                )}
                {subtext && (
                    <p className={styles['HelpBlock__BlockSubText']}>
                        {subtext}
                    </p>
                )}
            </div>
            {button && !isMobile && (
                <div className={styles['HelpBlock__Button']}>
                    <Button {...button} />
                </div>
            )}
        </Tag>
    );
};

HelpBlock.propTypes = {
    title: PropTypes.string,
    preamble: PropTypes.string,
    items: PropTypes.array,
};

HelpBlock.defaultProps = {
    title: '',
    text: '',
    items: [],
};

export default HelpBlock;
